export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** DateTime */
  PRISMIC_DateTime: any;
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  PRISMIC_Long: any;
  /** Raw JSON value */
  PRISMIC_Json: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};











export type BooleanQueryOperatorInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};


export type DateQueryOperatorInput = {
  eq?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type Directory = Node & {
  sourceInstanceName: Scalars['String'];
  absolutePath: Scalars['String'];
  relativePath: Scalars['String'];
  extension: Scalars['String'];
  size: Scalars['Int'];
  prettySize: Scalars['String'];
  modifiedTime: Scalars['Date'];
  accessTime: Scalars['Date'];
  changeTime: Scalars['Date'];
  birthTime: Scalars['Date'];
  root: Scalars['String'];
  dir: Scalars['String'];
  base: Scalars['String'];
  ext: Scalars['String'];
  name: Scalars['String'];
  relativeDirectory: Scalars['String'];
  dev: Scalars['Int'];
  mode: Scalars['Int'];
  nlink: Scalars['Int'];
  uid: Scalars['Int'];
  gid: Scalars['Int'];
  rdev: Scalars['Int'];
  ino: Scalars['Float'];
  atimeMs: Scalars['Float'];
  mtimeMs: Scalars['Float'];
  ctimeMs: Scalars['Float'];
  atime: Scalars['Date'];
  mtime: Scalars['Date'];
  ctime: Scalars['Date'];
  /** @deprecated Use `birthTime` instead */
  birthtime?: Maybe<Scalars['Date']>;
  /** @deprecated Use `birthTime` instead */
  birthtimeMs?: Maybe<Scalars['Float']>;
  blksize?: Maybe<Scalars['Int']>;
  blocks?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};


export type DirectoryModifiedTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type DirectoryAccessTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type DirectoryChangeTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type DirectoryBirthTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type DirectoryAtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type DirectoryMtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type DirectoryCtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type DirectoryConnection = {
  totalCount: Scalars['Int'];
  edges: Array<DirectoryEdge>;
  nodes: Array<Directory>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<DirectoryGroupConnection>;
};


export type DirectoryConnectionDistinctArgs = {
  field: DirectoryFieldsEnum;
};


export type DirectoryConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: DirectoryFieldsEnum;
};

export type DirectoryEdge = {
  next?: Maybe<Directory>;
  node: Directory;
  previous?: Maybe<Directory>;
};

export enum DirectoryFieldsEnum {
  sourceInstanceName = 'sourceInstanceName',
  absolutePath = 'absolutePath',
  relativePath = 'relativePath',
  extension = 'extension',
  size = 'size',
  prettySize = 'prettySize',
  modifiedTime = 'modifiedTime',
  accessTime = 'accessTime',
  changeTime = 'changeTime',
  birthTime = 'birthTime',
  root = 'root',
  dir = 'dir',
  base = 'base',
  ext = 'ext',
  name = 'name',
  relativeDirectory = 'relativeDirectory',
  dev = 'dev',
  mode = 'mode',
  nlink = 'nlink',
  uid = 'uid',
  gid = 'gid',
  rdev = 'rdev',
  ino = 'ino',
  atimeMs = 'atimeMs',
  mtimeMs = 'mtimeMs',
  ctimeMs = 'ctimeMs',
  atime = 'atime',
  mtime = 'mtime',
  ctime = 'ctime',
  birthtime = 'birthtime',
  birthtimeMs = 'birthtimeMs',
  blksize = 'blksize',
  blocks = 'blocks',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type'
}

export type DirectoryFilterInput = {
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<FloatQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  birthtime?: Maybe<DateQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type DirectoryGroupConnection = {
  totalCount: Scalars['Int'];
  edges: Array<DirectoryEdge>;
  nodes: Array<Directory>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type DirectorySortInput = {
  fields?: Maybe<Array<Maybe<DirectoryFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type File = Node & {
  sourceInstanceName: Scalars['String'];
  absolutePath: Scalars['String'];
  relativePath: Scalars['String'];
  extension: Scalars['String'];
  size: Scalars['Int'];
  prettySize: Scalars['String'];
  modifiedTime: Scalars['Date'];
  accessTime: Scalars['Date'];
  changeTime: Scalars['Date'];
  birthTime: Scalars['Date'];
  root: Scalars['String'];
  dir: Scalars['String'];
  base: Scalars['String'];
  ext: Scalars['String'];
  name: Scalars['String'];
  relativeDirectory: Scalars['String'];
  dev: Scalars['Int'];
  mode: Scalars['Int'];
  nlink: Scalars['Int'];
  uid: Scalars['Int'];
  gid: Scalars['Int'];
  rdev: Scalars['Int'];
  ino: Scalars['Float'];
  atimeMs: Scalars['Float'];
  mtimeMs: Scalars['Float'];
  ctimeMs: Scalars['Float'];
  atime: Scalars['Date'];
  mtime: Scalars['Date'];
  ctime: Scalars['Date'];
  /** @deprecated Use `birthTime` instead */
  birthtime?: Maybe<Scalars['Date']>;
  /** @deprecated Use `birthTime` instead */
  birthtimeMs?: Maybe<Scalars['Float']>;
  blksize?: Maybe<Scalars['Int']>;
  blocks?: Maybe<Scalars['Int']>;
  /** Copy file to static directory and return public url to it */
  publicURL?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};


export type FileModifiedTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type FileAccessTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type FileChangeTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type FileBirthTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type FileAtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type FileMtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type FileCtimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type FileConnection = {
  totalCount: Scalars['Int'];
  edges: Array<FileEdge>;
  nodes: Array<File>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<FileGroupConnection>;
};


export type FileConnectionDistinctArgs = {
  field: FileFieldsEnum;
};


export type FileConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: FileFieldsEnum;
};

export type FileEdge = {
  next?: Maybe<File>;
  node: File;
  previous?: Maybe<File>;
};

export enum FileFieldsEnum {
  sourceInstanceName = 'sourceInstanceName',
  absolutePath = 'absolutePath',
  relativePath = 'relativePath',
  extension = 'extension',
  size = 'size',
  prettySize = 'prettySize',
  modifiedTime = 'modifiedTime',
  accessTime = 'accessTime',
  changeTime = 'changeTime',
  birthTime = 'birthTime',
  root = 'root',
  dir = 'dir',
  base = 'base',
  ext = 'ext',
  name = 'name',
  relativeDirectory = 'relativeDirectory',
  dev = 'dev',
  mode = 'mode',
  nlink = 'nlink',
  uid = 'uid',
  gid = 'gid',
  rdev = 'rdev',
  ino = 'ino',
  atimeMs = 'atimeMs',
  mtimeMs = 'mtimeMs',
  ctimeMs = 'ctimeMs',
  atime = 'atime',
  mtime = 'mtime',
  ctime = 'ctime',
  birthtime = 'birthtime',
  birthtimeMs = 'birthtimeMs',
  blksize = 'blksize',
  blocks = 'blocks',
  publicURL = 'publicURL',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type'
}

export type FileFilterInput = {
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<FloatQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  birthtime?: Maybe<DateQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  publicURL?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type FileGroupConnection = {
  totalCount: Scalars['Int'];
  edges: Array<FileEdge>;
  nodes: Array<File>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type FileSortInput = {
  fields?: Maybe<Array<Maybe<FileFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type FloatQueryOperatorInput = {
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type GraphQlSourcePrismic = Node & {
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  typeName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
};

export type GraphQlSourcePrismicConnection = {
  totalCount: Scalars['Int'];
  edges: Array<GraphQlSourcePrismicEdge>;
  nodes: Array<GraphQlSourcePrismic>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<GraphQlSourcePrismicGroupConnection>;
};


export type GraphQlSourcePrismicConnectionDistinctArgs = {
  field: GraphQlSourcePrismicFieldsEnum;
};


export type GraphQlSourcePrismicConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: GraphQlSourcePrismicFieldsEnum;
};

export type GraphQlSourcePrismicEdge = {
  next?: Maybe<GraphQlSourcePrismic>;
  node: GraphQlSourcePrismic;
  previous?: Maybe<GraphQlSourcePrismic>;
};

export enum GraphQlSourcePrismicFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  typeName = 'typeName',
  fieldName = 'fieldName'
}

export type GraphQlSourcePrismicFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  typeName?: Maybe<StringQueryOperatorInput>;
  fieldName?: Maybe<StringQueryOperatorInput>;
};

export type GraphQlSourcePrismicGroupConnection = {
  totalCount: Scalars['Int'];
  edges: Array<GraphQlSourcePrismicEdge>;
  nodes: Array<GraphQlSourcePrismic>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type GraphQlSourcePrismicSortInput = {
  fields?: Maybe<Array<Maybe<GraphQlSourcePrismicFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type Internal = {
  content?: Maybe<Scalars['String']>;
  contentDigest: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fieldOwners?: Maybe<Array<Maybe<Scalars['String']>>>;
  ignoreType?: Maybe<Scalars['Boolean']>;
  mediaType?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  type: Scalars['String'];
};

export type InternalFilterInput = {
  content?: Maybe<StringQueryOperatorInput>;
  contentDigest?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
  fieldOwners?: Maybe<StringQueryOperatorInput>;
  ignoreType?: Maybe<BooleanQueryOperatorInput>;
  mediaType?: Maybe<StringQueryOperatorInput>;
  owner?: Maybe<StringQueryOperatorInput>;
  type?: Maybe<StringQueryOperatorInput>;
};

export type IntQueryOperatorInput = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


/** Node Interface */
export type Node = {
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};

export type NodeFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type NodeFilterListInput = {
  elemMatch?: Maybe<NodeFilterInput>;
};

export type PageInfo = {
  currentPage: Scalars['Int'];
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  perPage?: Maybe<Scalars['Int']>;
};

export type Prismic = {
  _allDocuments: Prismic__DocumentConnection;
  header_links?: Maybe<Prismic_Header_Links>;
  allHeader_linkss: Prismic_Header_LinksConnectionConnection;
  allHomes: Prismic_HomeConnectionConnection;
  standard_landing_page?: Maybe<Prismic_Standard_Landing_Page>;
  allStandard_landing_pages: Prismic_Standard_Landing_PageConnectionConnection;
  header_ctas?: Maybe<Prismic_Header_Ctas>;
  allHeader_ctass: Prismic_Header_CtasConnectionConnection;
  allRedirectss: Prismic_RedirectsConnectionConnection;
  allFooters: Prismic_FooterConnectionConnection;
};


export type Prismic_AllDocumentsArgs = {
  sortBy?: Maybe<Prismic_SortDocumentsBy>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Prismic_Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PrismicHeader_LinksArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type PrismicAllHeader_LinkssArgs = {
  sortBy?: Maybe<Prismic_SortHeader_Linksy>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Prismic_Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PrismicAllHomesArgs = {
  sortBy?: Maybe<Prismic_SortHomey>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Prismic_Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PrismicStandard_Landing_PageArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type PrismicAllStandard_Landing_PagesArgs = {
  sortBy?: Maybe<Prismic_SortStandard_Landing_Pagey>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Prismic_Similar>;
  where?: Maybe<Prismic_WhereStandard_Landing_Page>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PrismicHeader_CtasArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type PrismicAllHeader_CtassArgs = {
  sortBy?: Maybe<Prismic_SortHeader_Ctasy>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Prismic_Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PrismicAllRedirectssArgs = {
  sortBy?: Maybe<Prismic_SortRedirectsy>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Prismic_Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PrismicAllFootersArgs = {
  sortBy?: Maybe<Prismic_SortFootery>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<Array<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tags_in?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['PRISMIC_DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['PRISMIC_DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Prismic_Similar>;
  where?: Maybe<Prismic_WhereFooter>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A prismic document */
export type Prismic__Document = {
  _meta: Prismic_Meta;
};

/** A connection to a list of items. */
export type Prismic__DocumentConnection = {
  /** Information to aid in pagination. */
  pageInfo: Prismic_PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Prismic__DocumentEdge>>>;
  totalCount: Scalars['PRISMIC_Long'];
};

/** An edge in a connection. */
export type Prismic__DocumentEdge = {
  /** The item at the end of the edge. */
  node: Prismic__Document;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** An external link */
export type Prismic__ExternalLink = Prismic__Linkable & {
  url: Scalars['String'];
  target?: Maybe<Scalars['String']>;
  _linkType?: Maybe<Scalars['String']>;
};

/** A linked file */
export type Prismic__FileLink = Prismic__Linkable & {
  name: Scalars['String'];
  url: Scalars['String'];
  size: Scalars['PRISMIC_Long'];
  _linkType?: Maybe<Scalars['String']>;
};

/** A linked image */
export type Prismic__ImageLink = Prismic__Linkable & {
  name: Scalars['String'];
  url: Scalars['String'];
  size: Scalars['PRISMIC_Long'];
  height: Scalars['Int'];
  width: Scalars['Int'];
  _linkType?: Maybe<Scalars['String']>;
};

/** A prismic link */
export type Prismic__Linkable = {
  _linkType?: Maybe<Scalars['String']>;
};


export type Prismic_Footer = Prismic__Document & Prismic__Linkable & {
  column_title_1?: Maybe<Scalars['String']>;
  column_text_1?: Maybe<Array<Prismic_FooterColumn_Text_1>>;
  column_title_2?: Maybe<Scalars['String']>;
  column_text_2?: Maybe<Array<Prismic_FooterColumn_Text_2>>;
  column_title_3?: Maybe<Scalars['String']>;
  column_text_3?: Maybe<Array<Prismic_FooterColumn_Text_3>>;
  legal_links?: Maybe<Array<Prismic_FooterLegal_Links>>;
  _meta: Prismic_Meta;
  _linkType?: Maybe<Scalars['String']>;
};

export type Prismic_FooterColumn_Text_1 = {
  column_item?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_FooterColumn_Text_2 = {
  column_item?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_FooterColumn_Text_3 = {
  column_item?: Maybe<Scalars['PRISMIC_Json']>;
};

/** A connection to a list of items. */
export type Prismic_FooterConnectionConnection = {
  /** Information to aid in pagination. */
  pageInfo: Prismic_PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Prismic_FooterConnectionEdge>>>;
  totalCount: Scalars['PRISMIC_Long'];
};

/** An edge in a connection. */
export type Prismic_FooterConnectionEdge = {
  /** The item at the end of the edge. */
  node: Prismic_Footer;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type Prismic_FooterLegal_Links = {
  legal_link?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Header_Ctas = Prismic__Document & Prismic__Linkable & {
  ctas?: Maybe<Array<Prismic_Header_CtasCtas>>;
  _meta: Prismic_Meta;
  _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type Prismic_Header_CtasConnectionConnection = {
  /** Information to aid in pagination. */
  pageInfo: Prismic_PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Prismic_Header_CtasConnectionEdge>>>;
  totalCount: Scalars['PRISMIC_Long'];
};

/** An edge in a connection. */
export type Prismic_Header_CtasConnectionEdge = {
  /** The item at the end of the edge. */
  node: Prismic_Header_Ctas;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type Prismic_Header_CtasCtas = {
  cta_text?: Maybe<Scalars['String']>;
  cta_action?: Maybe<Scalars['String']>;
  cta_link?: Maybe<Prismic__Linkable>;
  drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type Prismic_Header_Links = Prismic__Document & Prismic__Linkable & {
  page_links?: Maybe<Array<Prismic_Header_LinksPage_Links>>;
  body?: Maybe<Array<Prismic_Header_LinksBody>>;
  _meta: Prismic_Meta;
  _linkType?: Maybe<Scalars['String']>;
};

export type Prismic_Header_LinksBody = Prismic_Header_LinksBodyDrawer_Button | Prismic_Header_LinksBodyDrawer_Column;

export type Prismic_Header_LinksBodyDrawer_Button = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Header_LinksBodyDrawer_ButtonPrimary>;
};

export type Prismic_Header_LinksBodyDrawer_ButtonPrimary = {
  drawer_button_text?: Maybe<Scalars['String']>;
};

export type Prismic_Header_LinksBodyDrawer_Column = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Header_LinksBodyDrawer_ColumnPrimary>;
  fields?: Maybe<Array<Prismic_Header_LinksBodyDrawer_ColumnFields>>;
};

export type Prismic_Header_LinksBodyDrawer_ColumnFields = {
  link?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Header_LinksBodyDrawer_ColumnPrimary = {
  column_title?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type Prismic_Header_LinksConnectionConnection = {
  /** Information to aid in pagination. */
  pageInfo: Prismic_PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Prismic_Header_LinksConnectionEdge>>>;
  totalCount: Scalars['PRISMIC_Long'];
};

/** An edge in a connection. */
export type Prismic_Header_LinksConnectionEdge = {
  /** The item at the end of the edge. */
  node: Prismic_Header_Links;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type Prismic_Header_LinksPage_Links = {
  link_text?: Maybe<Scalars['String']>;
  link?: Maybe<Prismic__Linkable>;
};

export type Prismic_Home = Prismic__Document & Prismic__Linkable & {
  body1?: Maybe<Array<Prismic_HomeBody1>>;
  body?: Maybe<Array<Prismic_HomeBody>>;
  _meta: Prismic_Meta;
  _linkType?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody = Prismic_HomeBodySeo_;

export type Prismic_HomeBody1 = Prismic_HomeBody1Home_Hero | Prismic_HomeBody1Count_Up | Prismic_HomeBody1Featured_Customers | Prismic_HomeBody1Benefit_Quadrant | Prismic_HomeBody1Media_Marquee | Prismic_HomeBody1Text_And_Image | Prismic_HomeBody1Testimonials | Prismic_HomeBody1Simple_Inline_Cta | Prismic_HomeBody1Content_Post | Prismic_HomeBody1Resources_Posts | Prismic_HomeBody1Newsletter_Signup | Prismic_HomeBody1Centered_Full_Width_Cta;

export type Prismic_HomeBody1Benefit_Quadrant = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Benefit_QuadrantPrimary>;
  fields?: Maybe<Array<Prismic_HomeBody1Benefit_QuadrantFields>>;
};

export type Prismic_HomeBody1Benefit_QuadrantFields = {
  image?: Maybe<Scalars['PRISMIC_Json']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_HomeBody1Benefit_QuadrantFieldsImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Benefit_QuadrantPrimary = {
  headline?: Maybe<Scalars['String']>;
  subheadline?: Maybe<Scalars['PRISMIC_Json']>;
  footer?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_HomeBody1Centered_Full_Width_Cta = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Centered_Full_Width_CtaPrimary>;
};

export type Prismic_HomeBody1Centered_Full_Width_CtaPrimary = {
  headline?: Maybe<Scalars['String']>;
  cta_action?: Maybe<Scalars['String']>;
  cta_text?: Maybe<Scalars['String']>;
  drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  cta_link?: Maybe<Prismic__Linkable>;
};

export type Prismic_HomeBody1Content_Post = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Content_PostPrimary>;
};

export type Prismic_HomeBody1Content_PostPrimary = {
  content_post?: Maybe<Prismic__Linkable>;
  content_icon?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_HomeBody1Count_Up = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Count_UpPrimary>;
  fields?: Maybe<Array<Prismic_HomeBody1Count_UpFields>>;
};

export type Prismic_HomeBody1Count_UpFields = {
  category_image?: Maybe<Scalars['PRISMIC_Json']>;
  category_title?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Float']>;
  suffix?: Maybe<Scalars['String']>;
  category_imageSharp?: Maybe<File>;
};


export type Prismic_HomeBody1Count_UpFieldsCategory_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Count_UpPrimary = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Featured_Customers = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Featured_CustomersPrimary>;
  fields?: Maybe<Array<Prismic_HomeBody1Featured_CustomersFields>>;
};

export type Prismic_HomeBody1Featured_CustomersFields = {
  company_logo?: Maybe<Scalars['PRISMIC_Json']>;
  company_link?: Maybe<Prismic__Linkable>;
};

export type Prismic_HomeBody1Featured_CustomersPrimary = {
  subtitle?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_HomeBody1Home_Hero = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Home_HeroPrimary>;
};

export type Prismic_HomeBody1Home_HeroPrimary = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['PRISMIC_Json']>;
  image?: Maybe<Scalars['PRISMIC_Json']>;
  cta_text?: Maybe<Scalars['String']>;
  cta_link?: Maybe<Prismic__Linkable>;
  imageSharp?: Maybe<File>;
};


export type Prismic_HomeBody1Home_HeroPrimaryImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Media_Marquee = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Media_MarqueePrimary>;
  fields?: Maybe<Array<Prismic_HomeBody1Media_MarqueeFields>>;
};

export type Prismic_HomeBody1Media_MarqueeFields = {
  feature_image?: Maybe<Scalars['PRISMIC_Json']>;
  feature_title?: Maybe<Scalars['String']>;
  feature_description?: Maybe<Scalars['PRISMIC_Json']>;
  feature_imageSharp?: Maybe<File>;
};


export type Prismic_HomeBody1Media_MarqueeFieldsFeature_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Media_MarqueePrimary = {
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['PRISMIC_Json']>;
  video_url?: Maybe<Scalars['PRISMIC_Json']>;
  footer?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_HomeBody1Newsletter_Signup = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Newsletter_SignupPrimary>;
};

export type Prismic_HomeBody1Newsletter_SignupPrimary = {
  description?: Maybe<Scalars['String']>;
  cta_button_text?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Resources_Posts = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Resources_PostsPrimary>;
  fields?: Maybe<Array<Prismic_HomeBody1Resources_PostsFields>>;
};

export type Prismic_HomeBody1Resources_PostsFields = {
  content_post?: Maybe<Prismic__Linkable>;
};

export type Prismic_HomeBody1Resources_PostsPrimary = {
  headline?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Simple_Inline_Cta = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Simple_Inline_CtaPrimary>;
};

export type Prismic_HomeBody1Simple_Inline_CtaPrimary = {
  headline?: Maybe<Scalars['String']>;
  subheadline?: Maybe<Scalars['PRISMIC_Json']>;
  cta_text?: Maybe<Scalars['String']>;
  cta_action?: Maybe<Scalars['String']>;
  cta_link?: Maybe<Prismic__Linkable>;
  drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type Prismic_HomeBody1Testimonials = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1TestimonialsPrimary>;
  fields?: Maybe<Array<Prismic_HomeBody1TestimonialsFields>>;
};

export type Prismic_HomeBody1TestimonialsFields = {
  person_image?: Maybe<Scalars['PRISMIC_Json']>;
  company_image?: Maybe<Scalars['PRISMIC_Json']>;
  quote?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  person_title?: Maybe<Scalars['String']>;
  person_imageSharp?: Maybe<File>;
  company_imageSharp?: Maybe<File>;
};


export type Prismic_HomeBody1TestimonialsFieldsPerson_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_HomeBody1TestimonialsFieldsCompany_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1TestimonialsPrimary = {
  title?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Text_And_Image = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBody1Text_And_ImagePrimary>;
  fields?: Maybe<Array<Prismic_HomeBody1Text_And_ImageFields>>;
};

export type Prismic_HomeBody1Text_And_ImageFields = {
  bullet_point?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBody1Text_And_ImagePrimary = {
  title?: Maybe<Scalars['String']>;
  display_new_badge?: Maybe<Scalars['PRISMIC_Json']>;
  text?: Maybe<Scalars['PRISMIC_Json']>;
  image?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_HomeBody1Text_And_ImagePrimaryImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_HomeBodySeo_ = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_HomeBodySeo_Primary>;
};

export type Prismic_HomeBodySeo_Primary = {
  meta_title?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type Prismic_HomeConnectionConnection = {
  /** Information to aid in pagination. */
  pageInfo: Prismic_PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Prismic_HomeConnectionEdge>>>;
  totalCount: Scalars['PRISMIC_Long'];
};

/** An edge in a connection. */
export type Prismic_HomeConnectionEdge = {
  /** The item at the end of the edge. */
  node: Prismic_Home;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};



export type Prismic_Meta = {
  /** The id of the document. */
  id: Scalars['String'];
  /** The uid of the document. */
  uid?: Maybe<Scalars['String']>;
  /** The type of the document. */
  type: Scalars['String'];
  /** The tags of the document. */
  tags: Array<Scalars['String']>;
  /** The language of the document. */
  lang: Scalars['String'];
  /** Alternate languages the document. */
  alternateLanguages: Array<Prismic_RelatedDocument>;
  /** The first publication date of the document. */
  firstPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
  /** The last publication date of the document. */
  lastPublicationDate?: Maybe<Scalars['PRISMIC_DateTime']>;
};

/** Information about pagination in a connection. */
export type Prismic_PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type Prismic_Redirects = Prismic__Document & Prismic__Linkable & {
  redirect?: Maybe<Array<Prismic_RedirectsRedirect>>;
  _meta: Prismic_Meta;
  _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type Prismic_RedirectsConnectionConnection = {
  /** Information to aid in pagination. */
  pageInfo: Prismic_PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Prismic_RedirectsConnectionEdge>>>;
  totalCount: Scalars['PRISMIC_Long'];
};

/** An edge in a connection. */
export type Prismic_RedirectsConnectionEdge = {
  /** The item at the end of the edge. */
  node: Prismic_Redirects;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type Prismic_RedirectsRedirect = {
  old_url?: Maybe<Scalars['String']>;
  new_url?: Maybe<Scalars['String']>;
};

export type Prismic_RelatedDocument = {
  /** The id of the document. */
  id: Scalars['String'];
  /** The uid of the document. */
  uid?: Maybe<Scalars['String']>;
  /** The type of the document. */
  type: Scalars['String'];
  /** The language of the document. */
  lang: Scalars['String'];
};

export type Prismic_Similar = {
  documentId: Scalars['String'];
  max: Scalars['Int'];
};

export enum Prismic_SortDocumentsBy {
  meta_firstPublicationDate_ASC = 'meta_firstPublicationDate_ASC',
  meta_firstPublicationDate_DESC = 'meta_firstPublicationDate_DESC',
  meta_lastPublicationDate_ASC = 'meta_lastPublicationDate_ASC',
  meta_lastPublicationDate_DESC = 'meta_lastPublicationDate_DESC'
}

export enum Prismic_SortFootery {
  meta_firstPublicationDate_ASC = 'meta_firstPublicationDate_ASC',
  meta_firstPublicationDate_DESC = 'meta_firstPublicationDate_DESC',
  meta_lastPublicationDate_ASC = 'meta_lastPublicationDate_ASC',
  meta_lastPublicationDate_DESC = 'meta_lastPublicationDate_DESC',
  column_title_1_ASC = 'column_title_1_ASC',
  column_title_1_DESC = 'column_title_1_DESC',
  column_title_2_ASC = 'column_title_2_ASC',
  column_title_2_DESC = 'column_title_2_DESC',
  column_title_3_ASC = 'column_title_3_ASC',
  column_title_3_DESC = 'column_title_3_DESC'
}

export enum Prismic_SortHeader_Ctasy {
  meta_firstPublicationDate_ASC = 'meta_firstPublicationDate_ASC',
  meta_firstPublicationDate_DESC = 'meta_firstPublicationDate_DESC',
  meta_lastPublicationDate_ASC = 'meta_lastPublicationDate_ASC',
  meta_lastPublicationDate_DESC = 'meta_lastPublicationDate_DESC'
}

export enum Prismic_SortHeader_Linksy {
  meta_firstPublicationDate_ASC = 'meta_firstPublicationDate_ASC',
  meta_firstPublicationDate_DESC = 'meta_firstPublicationDate_DESC',
  meta_lastPublicationDate_ASC = 'meta_lastPublicationDate_ASC',
  meta_lastPublicationDate_DESC = 'meta_lastPublicationDate_DESC'
}

export enum Prismic_SortHomey {
  meta_firstPublicationDate_ASC = 'meta_firstPublicationDate_ASC',
  meta_firstPublicationDate_DESC = 'meta_firstPublicationDate_DESC',
  meta_lastPublicationDate_ASC = 'meta_lastPublicationDate_ASC',
  meta_lastPublicationDate_DESC = 'meta_lastPublicationDate_DESC'
}

export enum Prismic_SortRedirectsy {
  meta_firstPublicationDate_ASC = 'meta_firstPublicationDate_ASC',
  meta_firstPublicationDate_DESC = 'meta_firstPublicationDate_DESC',
  meta_lastPublicationDate_ASC = 'meta_lastPublicationDate_ASC',
  meta_lastPublicationDate_DESC = 'meta_lastPublicationDate_DESC'
}

export enum Prismic_SortStandard_Landing_Pagey {
  meta_firstPublicationDate_ASC = 'meta_firstPublicationDate_ASC',
  meta_firstPublicationDate_DESC = 'meta_firstPublicationDate_DESC',
  meta_lastPublicationDate_ASC = 'meta_lastPublicationDate_ASC',
  meta_lastPublicationDate_DESC = 'meta_lastPublicationDate_DESC'
}

export type Prismic_Standard_Landing_Page = Prismic__Document & Prismic__Linkable & {
  header_links?: Maybe<Prismic__Linkable>;
  header_ctas?: Maybe<Prismic__Linkable>;
  body?: Maybe<Array<Prismic_Standard_Landing_PageBody>>;
  body1?: Maybe<Array<Prismic_Standard_Landing_PageBody1>>;
  _meta: Prismic_Meta;
  _linkType?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBody = Prismic_Standard_Landing_PageBodyLanding_Page_Hero | Prismic_Standard_Landing_PageBodyTestimonial | Prismic_Standard_Landing_PageBodyThree_Simple_Cards | Prismic_Standard_Landing_PageBodyFull_Width_Cta | Prismic_Standard_Landing_PageBodyText_And_Image | Prismic_Standard_Landing_PageBodyThree_Simple_Circles | Prismic_Standard_Landing_PageBodyYoutube_Video | Prismic_Standard_Landing_PageBodyNested_Content | Prismic_Standard_Landing_PageBodyTable | Prismic_Standard_Landing_PageBodyCentered_Hero | Prismic_Standard_Landing_PageBodyImage_Grid | Prismic_Standard_Landing_PageBodyRich_Text | Prismic_Standard_Landing_PageBodyBenefit_Quadrant | Prismic_Standard_Landing_PageBodyMedia_Marquee | Prismic_Standard_Landing_PageBodyTestimonials | Prismic_Standard_Landing_PageBodyImageAndList | Prismic_Standard_Landing_PageBodyImage_Carousel | Prismic_Standard_Landing_PageBodyFeatured_Customers | Prismic_Standard_Landing_PageBodyNewsletter_Signup | Prismic_Standard_Landing_PageBodyCentered_Full_Width_Cta;

export type Prismic_Standard_Landing_PageBody1 = Prismic_Standard_Landing_PageBody1Seo_;

export type Prismic_Standard_Landing_PageBody1Seo_ = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBody1Seo_Primary>;
};

export type Prismic_Standard_Landing_PageBody1Seo_Primary = {
  meta_title?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyBenefit_Quadrant = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyBenefit_QuadrantPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyBenefit_QuadrantFields>>;
};

export type Prismic_Standard_Landing_PageBodyBenefit_QuadrantFields = {
  image?: Maybe<Scalars['PRISMIC_Json']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyBenefit_QuadrantFieldsImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyBenefit_QuadrantPrimary = {
  headline?: Maybe<Scalars['String']>;
  subheadline?: Maybe<Scalars['PRISMIC_Json']>;
  footer?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Standard_Landing_PageBodyCentered_Full_Width_Cta = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyCentered_Full_Width_CtaPrimary>;
};

export type Prismic_Standard_Landing_PageBodyCentered_Full_Width_CtaPrimary = {
  headline?: Maybe<Scalars['String']>;
  cta_action?: Maybe<Scalars['String']>;
  cta_text?: Maybe<Scalars['String']>;
  drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  cta_link?: Maybe<Prismic__Linkable>;
};

export type Prismic_Standard_Landing_PageBodyCentered_Hero = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyCentered_HeroPrimary>;
};

export type Prismic_Standard_Landing_PageBodyCentered_HeroPrimary = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['PRISMIC_Json']>;
  cta_text?: Maybe<Scalars['String']>;
  cta_link?: Maybe<Prismic__Linkable>;
  cta_microcopy?: Maybe<Scalars['PRISMIC_Json']>;
  image?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyCentered_HeroPrimaryImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyFeatured_Customers = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyFeatured_CustomersPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyFeatured_CustomersFields>>;
};

export type Prismic_Standard_Landing_PageBodyFeatured_CustomersFields = {
  company_logo?: Maybe<Scalars['PRISMIC_Json']>;
  company_link?: Maybe<Prismic__Linkable>;
};

export type Prismic_Standard_Landing_PageBodyFeatured_CustomersPrimary = {
  subtitle?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Standard_Landing_PageBodyFull_Width_Cta = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyFull_Width_CtaPrimary>;
};

export type Prismic_Standard_Landing_PageBodyFull_Width_CtaPrimary = {
  heading?: Maybe<Scalars['String']>;
  cta_text?: Maybe<Scalars['String']>;
  cta_action?: Maybe<Scalars['String']>;
  drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  cta_link?: Maybe<Prismic__Linkable>;
  image?: Maybe<Scalars['PRISMIC_Json']>;
  fine_print?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyFull_Width_CtaPrimaryImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyImage_Carousel = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyImage_CarouselPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyImage_CarouselFields>>;
};

export type Prismic_Standard_Landing_PageBodyImage_CarouselFields = {
  image1?: Maybe<Scalars['PRISMIC_Json']>;
  image_caption?: Maybe<Scalars['PRISMIC_Json']>;
  image1Sharp?: Maybe<File>;
  image_captionSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyImage_CarouselFieldsImage1SharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_Standard_Landing_PageBodyImage_CarouselFieldsImage_CaptionSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyImage_CarouselPrimary = {
  primary_caption?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Standard_Landing_PageBodyImage_Grid = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyImage_GridPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyImage_GridFields>>;
};

export type Prismic_Standard_Landing_PageBodyImage_GridFields = {
  image?: Maybe<Scalars['PRISMIC_Json']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyImage_GridFieldsImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyImage_GridPrimary = {
  title?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyImageAndList = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyImageAndListPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyImageAndListFields>>;
};

export type Prismic_Standard_Landing_PageBodyImageAndListFields = {
  image1?: Maybe<Scalars['PRISMIC_Json']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['PRISMIC_Json']>;
  image1Sharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyImageAndListFieldsImage1SharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyImageAndListPrimary = {
  title1?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyLanding_Page_Hero = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyLanding_Page_HeroPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyLanding_Page_HeroFields>>;
};

export type Prismic_Standard_Landing_PageBodyLanding_Page_HeroFields = {
  quick_fact_text?: Maybe<Scalars['PRISMIC_Json']>;
  quick_fact_icon?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyLanding_Page_HeroPrimary = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['PRISMIC_Json']>;
  image?: Maybe<Scalars['PRISMIC_Json']>;
  cta_text?: Maybe<Scalars['String']>;
  cta_link?: Maybe<Prismic__Linkable>;
  drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  cta_action?: Maybe<Scalars['String']>;
  cta_microcopy?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyLanding_Page_HeroPrimaryImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyMedia_Marquee = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyMedia_MarqueePrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyMedia_MarqueeFields>>;
};

export type Prismic_Standard_Landing_PageBodyMedia_MarqueeFields = {
  feature_image?: Maybe<Scalars['PRISMIC_Json']>;
  feature_title?: Maybe<Scalars['String']>;
  feature_description?: Maybe<Scalars['PRISMIC_Json']>;
  feature_imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyMedia_MarqueeFieldsFeature_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyMedia_MarqueePrimary = {
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['PRISMIC_Json']>;
  video_url?: Maybe<Scalars['PRISMIC_Json']>;
  footer?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Standard_Landing_PageBodyNested_Content = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyNested_ContentPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyNested_ContentFields>>;
};

export type Prismic_Standard_Landing_PageBodyNested_ContentFields = {
  header?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Standard_Landing_PageBodyNested_ContentPrimary = {
  title?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyNewsletter_Signup = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyNewsletter_SignupPrimary>;
};

export type Prismic_Standard_Landing_PageBodyNewsletter_SignupPrimary = {
  description?: Maybe<Scalars['String']>;
  cta_button_text?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyRich_Text = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyRich_TextPrimary>;
};

export type Prismic_Standard_Landing_PageBodyRich_TextPrimary = {
  title1?: Maybe<Scalars['String']>;
  rich_text?: Maybe<Scalars['PRISMIC_Json']>;
};

export type Prismic_Standard_Landing_PageBodyTable = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyTablePrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyTableFields>>;
};

export type Prismic_Standard_Landing_PageBodyTableFields = {
  column_1_entry?: Maybe<Scalars['String']>;
  column_2_entry?: Maybe<Scalars['String']>;
  column_3_entry?: Maybe<Scalars['String']>;
  column_4_entry?: Maybe<Scalars['String']>;
  column_5_entry?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyTablePrimary = {
  heading?: Maybe<Scalars['String']>;
  table_title?: Maybe<Scalars['String']>;
  table_caption_rich_text?: Maybe<Scalars['PRISMIC_Json']>;
  column_1_header?: Maybe<Scalars['String']>;
  column_2_header?: Maybe<Scalars['String']>;
  column_3_header?: Maybe<Scalars['String']>;
  column_4_header?: Maybe<Scalars['String']>;
  column_5_header?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyTestimonial = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyTestimonialPrimary>;
};

export type Prismic_Standard_Landing_PageBodyTestimonialPrimary = {
  quote?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  person_title?: Maybe<Scalars['String']>;
  person_image?: Maybe<Scalars['PRISMIC_Json']>;
  company_image?: Maybe<Scalars['PRISMIC_Json']>;
  person_imageSharp?: Maybe<File>;
  company_imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyTestimonialPrimaryPerson_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_Standard_Landing_PageBodyTestimonialPrimaryCompany_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyTestimonials = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyTestimonialsPrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyTestimonialsFields>>;
};

export type Prismic_Standard_Landing_PageBodyTestimonialsFields = {
  person_image?: Maybe<Scalars['PRISMIC_Json']>;
  company_image?: Maybe<Scalars['PRISMIC_Json']>;
  quote?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  person_title?: Maybe<Scalars['String']>;
  person_imageSharp?: Maybe<File>;
  company_imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyTestimonialsFieldsPerson_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_Standard_Landing_PageBodyTestimonialsFieldsCompany_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyTestimonialsPrimary = {
  title?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyText_And_Image = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyText_And_ImagePrimary>;
  fields?: Maybe<Array<Prismic_Standard_Landing_PageBodyText_And_ImageFields>>;
};

export type Prismic_Standard_Landing_PageBodyText_And_ImageFields = {
  bullet_point?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyText_And_ImagePrimary = {
  title?: Maybe<Scalars['String']>;
  display_new_badge?: Maybe<Scalars['PRISMIC_Json']>;
  text?: Maybe<Scalars['PRISMIC_Json']>;
  image?: Maybe<Scalars['PRISMIC_Json']>;
  imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyText_And_ImagePrimaryImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyThree_Simple_Cards = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyThree_Simple_CardsPrimary>;
};

export type Prismic_Standard_Landing_PageBodyThree_Simple_CardsPrimary = {
  title?: Maybe<Scalars['String']>;
  card_1_image?: Maybe<Scalars['PRISMIC_Json']>;
  card_1_title?: Maybe<Scalars['String']>;
  card_1_description?: Maybe<Scalars['PRISMIC_Json']>;
  card_2_image?: Maybe<Scalars['PRISMIC_Json']>;
  card_2_title?: Maybe<Scalars['String']>;
  card_2_description?: Maybe<Scalars['PRISMIC_Json']>;
  card_3_image?: Maybe<Scalars['PRISMIC_Json']>;
  card_3_title?: Maybe<Scalars['String']>;
  card_3_description?: Maybe<Scalars['PRISMIC_Json']>;
  card_1_imageSharp?: Maybe<File>;
  card_2_imageSharp?: Maybe<File>;
  card_3_imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyThree_Simple_CardsPrimaryCard_1_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_Standard_Landing_PageBodyThree_Simple_CardsPrimaryCard_2_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_Standard_Landing_PageBodyThree_Simple_CardsPrimaryCard_3_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyThree_Simple_Circles = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyThree_Simple_CirclesPrimary>;
};

export type Prismic_Standard_Landing_PageBodyThree_Simple_CirclesPrimary = {
  title?: Maybe<Scalars['String']>;
  circle_1_image?: Maybe<Scalars['PRISMIC_Json']>;
  circle_1_title?: Maybe<Scalars['String']>;
  circle_1_description?: Maybe<Scalars['PRISMIC_Json']>;
  circle_2_image?: Maybe<Scalars['PRISMIC_Json']>;
  circle_2_title?: Maybe<Scalars['String']>;
  circle_2_description?: Maybe<Scalars['PRISMIC_Json']>;
  circle_3_image?: Maybe<Scalars['PRISMIC_Json']>;
  circle_3_title?: Maybe<Scalars['String']>;
  circle_3_description?: Maybe<Scalars['PRISMIC_Json']>;
  circle_1_imageSharp?: Maybe<File>;
  circle_2_imageSharp?: Maybe<File>;
  circle_3_imageSharp?: Maybe<File>;
};


export type Prismic_Standard_Landing_PageBodyThree_Simple_CirclesPrimaryCircle_1_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_Standard_Landing_PageBodyThree_Simple_CirclesPrimaryCircle_2_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};


export type Prismic_Standard_Landing_PageBodyThree_Simple_CirclesPrimaryCircle_3_ImageSharpArgs = {
  crop?: Maybe<Scalars['String']>;
};

export type Prismic_Standard_Landing_PageBodyYoutube_Video = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  primary?: Maybe<Prismic_Standard_Landing_PageBodyYoutube_VideoPrimary>;
};

export type Prismic_Standard_Landing_PageBodyYoutube_VideoPrimary = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['PRISMIC_Json']>;
};

/** A connection to a list of items. */
export type Prismic_Standard_Landing_PageConnectionConnection = {
  /** Information to aid in pagination. */
  pageInfo: Prismic_PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<Prismic_Standard_Landing_PageConnectionEdge>>>;
  totalCount: Scalars['PRISMIC_Long'];
};

/** An edge in a connection. */
export type Prismic_Standard_Landing_PageConnectionEdge = {
  /** The item at the end of the edge. */
  node: Prismic_Standard_Landing_Page;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

export type Prismic_WhereFooter = {
  column_title_1?: Maybe<Scalars['String']>;
  column_title_1_fulltext?: Maybe<Scalars['String']>;
  column_title_2?: Maybe<Scalars['String']>;
  column_title_2_fulltext?: Maybe<Scalars['String']>;
  column_title_3?: Maybe<Scalars['String']>;
  column_title_3_fulltext?: Maybe<Scalars['String']>;
};

export type Prismic_WhereStandard_Landing_Page = {
  /** header_links */
  header_links?: Maybe<Scalars['String']>;
  /** header_ctas */
  header_ctas?: Maybe<Scalars['String']>;
};

export type Query = {
  file?: Maybe<File>;
  allFile: FileConnection;
  directory?: Maybe<Directory>;
  allDirectory: DirectoryConnection;
  sitePage?: Maybe<SitePage>;
  allSitePage: SitePageConnection;
  site?: Maybe<Site>;
  allSite: SiteConnection;
  graphQlSourcePrismic?: Maybe<GraphQlSourcePrismic>;
  allGraphQlSourcePrismic: GraphQlSourcePrismicConnection;
  siteBuildMetadata?: Maybe<SiteBuildMetadata>;
  allSiteBuildMetadata: SiteBuildMetadataConnection;
  sitePlugin?: Maybe<SitePlugin>;
  allSitePlugin: SitePluginConnection;
  prismic: Prismic;
};


export type QueryFileArgs = {
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<FloatQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  birthtime?: Maybe<DateQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  publicURL?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};


export type QueryAllFileArgs = {
  filter?: Maybe<FileFilterInput>;
  sort?: Maybe<FileSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryDirectoryArgs = {
  sourceInstanceName?: Maybe<StringQueryOperatorInput>;
  absolutePath?: Maybe<StringQueryOperatorInput>;
  relativePath?: Maybe<StringQueryOperatorInput>;
  extension?: Maybe<StringQueryOperatorInput>;
  size?: Maybe<IntQueryOperatorInput>;
  prettySize?: Maybe<StringQueryOperatorInput>;
  modifiedTime?: Maybe<DateQueryOperatorInput>;
  accessTime?: Maybe<DateQueryOperatorInput>;
  changeTime?: Maybe<DateQueryOperatorInput>;
  birthTime?: Maybe<DateQueryOperatorInput>;
  root?: Maybe<StringQueryOperatorInput>;
  dir?: Maybe<StringQueryOperatorInput>;
  base?: Maybe<StringQueryOperatorInput>;
  ext?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  relativeDirectory?: Maybe<StringQueryOperatorInput>;
  dev?: Maybe<IntQueryOperatorInput>;
  mode?: Maybe<IntQueryOperatorInput>;
  nlink?: Maybe<IntQueryOperatorInput>;
  uid?: Maybe<IntQueryOperatorInput>;
  gid?: Maybe<IntQueryOperatorInput>;
  rdev?: Maybe<IntQueryOperatorInput>;
  ino?: Maybe<FloatQueryOperatorInput>;
  atimeMs?: Maybe<FloatQueryOperatorInput>;
  mtimeMs?: Maybe<FloatQueryOperatorInput>;
  ctimeMs?: Maybe<FloatQueryOperatorInput>;
  atime?: Maybe<DateQueryOperatorInput>;
  mtime?: Maybe<DateQueryOperatorInput>;
  ctime?: Maybe<DateQueryOperatorInput>;
  birthtime?: Maybe<DateQueryOperatorInput>;
  birthtimeMs?: Maybe<FloatQueryOperatorInput>;
  blksize?: Maybe<IntQueryOperatorInput>;
  blocks?: Maybe<IntQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};


export type QueryAllDirectoryArgs = {
  filter?: Maybe<DirectoryFilterInput>;
  sort?: Maybe<DirectorySortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySitePageArgs = {
  path?: Maybe<StringQueryOperatorInput>;
  component?: Maybe<StringQueryOperatorInput>;
  internalComponentName?: Maybe<StringQueryOperatorInput>;
  componentChunkName?: Maybe<StringQueryOperatorInput>;
  matchPath?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  isCreatedByStatefulCreatePages?: Maybe<BooleanQueryOperatorInput>;
  context?: Maybe<SitePageContextFilterInput>;
  pluginCreator?: Maybe<SitePluginFilterInput>;
  pluginCreatorId?: Maybe<StringQueryOperatorInput>;
  componentPath?: Maybe<StringQueryOperatorInput>;
};


export type QueryAllSitePageArgs = {
  filter?: Maybe<SitePageFilterInput>;
  sort?: Maybe<SitePageSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySiteArgs = {
  buildTime?: Maybe<DateQueryOperatorInput>;
  siteMetadata?: Maybe<SiteSiteMetadataFilterInput>;
  port?: Maybe<IntQueryOperatorInput>;
  host?: Maybe<StringQueryOperatorInput>;
  polyfill?: Maybe<BooleanQueryOperatorInput>;
  pathPrefix?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};


export type QueryAllSiteArgs = {
  filter?: Maybe<SiteFilterInput>;
  sort?: Maybe<SiteSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGraphQlSourcePrismicArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  typeName?: Maybe<StringQueryOperatorInput>;
  fieldName?: Maybe<StringQueryOperatorInput>;
};


export type QueryAllGraphQlSourcePrismicArgs = {
  filter?: Maybe<GraphQlSourcePrismicFilterInput>;
  sort?: Maybe<GraphQlSourcePrismicSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySiteBuildMetadataArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  buildTime?: Maybe<DateQueryOperatorInput>;
};


export type QueryAllSiteBuildMetadataArgs = {
  filter?: Maybe<SiteBuildMetadataFilterInput>;
  sort?: Maybe<SiteBuildMetadataSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySitePluginArgs = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  resolve?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
  pluginOptions?: Maybe<SitePluginPluginOptionsFilterInput>;
  nodeAPIs?: Maybe<StringQueryOperatorInput>;
  browserAPIs?: Maybe<StringQueryOperatorInput>;
  ssrAPIs?: Maybe<StringQueryOperatorInput>;
  pluginFilepath?: Maybe<StringQueryOperatorInput>;
  packageJson?: Maybe<SitePluginPackageJsonFilterInput>;
};


export type QueryAllSitePluginArgs = {
  filter?: Maybe<SitePluginFilterInput>;
  sort?: Maybe<SitePluginSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Site = Node & {
  buildTime?: Maybe<Scalars['Date']>;
  siteMetadata?: Maybe<SiteSiteMetadata>;
  port?: Maybe<Scalars['Int']>;
  host?: Maybe<Scalars['String']>;
  polyfill?: Maybe<Scalars['Boolean']>;
  pathPrefix?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
};


export type SiteBuildTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type SiteBuildMetadata = Node & {
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  buildTime?: Maybe<Scalars['Date']>;
};


export type SiteBuildMetadataBuildTimeArgs = {
  formatString?: Maybe<Scalars['String']>;
  fromNow?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type SiteBuildMetadataConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SiteBuildMetadataEdge>;
  nodes: Array<SiteBuildMetadata>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<SiteBuildMetadataGroupConnection>;
};


export type SiteBuildMetadataConnectionDistinctArgs = {
  field: SiteBuildMetadataFieldsEnum;
};


export type SiteBuildMetadataConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: SiteBuildMetadataFieldsEnum;
};

export type SiteBuildMetadataEdge = {
  next?: Maybe<SiteBuildMetadata>;
  node: SiteBuildMetadata;
  previous?: Maybe<SiteBuildMetadata>;
};

export enum SiteBuildMetadataFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  buildTime = 'buildTime'
}

export type SiteBuildMetadataFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  buildTime?: Maybe<DateQueryOperatorInput>;
};

export type SiteBuildMetadataGroupConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SiteBuildMetadataEdge>;
  nodes: Array<SiteBuildMetadata>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type SiteBuildMetadataSortInput = {
  fields?: Maybe<Array<Maybe<SiteBuildMetadataFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type SiteConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SiteEdge>;
  nodes: Array<Site>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<SiteGroupConnection>;
};


export type SiteConnectionDistinctArgs = {
  field: SiteFieldsEnum;
};


export type SiteConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: SiteFieldsEnum;
};

export type SiteEdge = {
  next?: Maybe<Site>;
  node: Site;
  previous?: Maybe<Site>;
};

export enum SiteFieldsEnum {
  buildTime = 'buildTime',
  siteMetadata___title = 'siteMetadata___title',
  siteMetadata___description = 'siteMetadata___description',
  siteMetadata___author = 'siteMetadata___author',
  siteMetadata___lang = 'siteMetadata___lang',
  siteMetadata___siteUrl = 'siteMetadata___siteUrl',
  siteMetadata___image = 'siteMetadata___image',
  port = 'port',
  host = 'host',
  polyfill = 'polyfill',
  pathPrefix = 'pathPrefix',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type'
}

export type SiteFilterInput = {
  buildTime?: Maybe<DateQueryOperatorInput>;
  siteMetadata?: Maybe<SiteSiteMetadataFilterInput>;
  port?: Maybe<IntQueryOperatorInput>;
  host?: Maybe<StringQueryOperatorInput>;
  polyfill?: Maybe<BooleanQueryOperatorInput>;
  pathPrefix?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
};

export type SiteGroupConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SiteEdge>;
  nodes: Array<Site>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type SitePage = Node & {
  path: Scalars['String'];
  component: Scalars['String'];
  internalComponentName: Scalars['String'];
  componentChunkName: Scalars['String'];
  matchPath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  isCreatedByStatefulCreatePages?: Maybe<Scalars['Boolean']>;
  context?: Maybe<SitePageContext>;
  pluginCreator?: Maybe<SitePlugin>;
  pluginCreatorId?: Maybe<Scalars['String']>;
  componentPath?: Maybe<Scalars['String']>;
};

export type SitePageConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SitePageEdge>;
  nodes: Array<SitePage>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<SitePageGroupConnection>;
};


export type SitePageConnectionDistinctArgs = {
  field: SitePageFieldsEnum;
};


export type SitePageConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: SitePageFieldsEnum;
};

export type SitePageContext = {
  prismicPreviewPage?: Maybe<Scalars['Boolean']>;
  rootQuery?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  previewType?: Maybe<Scalars['String']>;
};

export type SitePageContextFilterInput = {
  prismicPreviewPage?: Maybe<BooleanQueryOperatorInput>;
  rootQuery?: Maybe<StringQueryOperatorInput>;
  uid?: Maybe<StringQueryOperatorInput>;
  lang?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  previewType?: Maybe<StringQueryOperatorInput>;
};

export type SitePageEdge = {
  next?: Maybe<SitePage>;
  node: SitePage;
  previous?: Maybe<SitePage>;
};

export enum SitePageFieldsEnum {
  path = 'path',
  component = 'component',
  internalComponentName = 'internalComponentName',
  componentChunkName = 'componentChunkName',
  matchPath = 'matchPath',
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  isCreatedByStatefulCreatePages = 'isCreatedByStatefulCreatePages',
  context___prismicPreviewPage = 'context___prismicPreviewPage',
  context___rootQuery = 'context___rootQuery',
  context___uid = 'context___uid',
  context___lang = 'context___lang',
  context___id = 'context___id',
  context___previewType = 'context___previewType',
  pluginCreator___id = 'pluginCreator___id',
  pluginCreator___parent___id = 'pluginCreator___parent___id',
  pluginCreator___parent___parent___id = 'pluginCreator___parent___parent___id',
  pluginCreator___parent___parent___children = 'pluginCreator___parent___parent___children',
  pluginCreator___parent___children = 'pluginCreator___parent___children',
  pluginCreator___parent___children___id = 'pluginCreator___parent___children___id',
  pluginCreator___parent___children___children = 'pluginCreator___parent___children___children',
  pluginCreator___parent___internal___content = 'pluginCreator___parent___internal___content',
  pluginCreator___parent___internal___contentDigest = 'pluginCreator___parent___internal___contentDigest',
  pluginCreator___parent___internal___description = 'pluginCreator___parent___internal___description',
  pluginCreator___parent___internal___fieldOwners = 'pluginCreator___parent___internal___fieldOwners',
  pluginCreator___parent___internal___ignoreType = 'pluginCreator___parent___internal___ignoreType',
  pluginCreator___parent___internal___mediaType = 'pluginCreator___parent___internal___mediaType',
  pluginCreator___parent___internal___owner = 'pluginCreator___parent___internal___owner',
  pluginCreator___parent___internal___type = 'pluginCreator___parent___internal___type',
  pluginCreator___children = 'pluginCreator___children',
  pluginCreator___children___id = 'pluginCreator___children___id',
  pluginCreator___children___parent___id = 'pluginCreator___children___parent___id',
  pluginCreator___children___parent___children = 'pluginCreator___children___parent___children',
  pluginCreator___children___children = 'pluginCreator___children___children',
  pluginCreator___children___children___id = 'pluginCreator___children___children___id',
  pluginCreator___children___children___children = 'pluginCreator___children___children___children',
  pluginCreator___children___internal___content = 'pluginCreator___children___internal___content',
  pluginCreator___children___internal___contentDigest = 'pluginCreator___children___internal___contentDigest',
  pluginCreator___children___internal___description = 'pluginCreator___children___internal___description',
  pluginCreator___children___internal___fieldOwners = 'pluginCreator___children___internal___fieldOwners',
  pluginCreator___children___internal___ignoreType = 'pluginCreator___children___internal___ignoreType',
  pluginCreator___children___internal___mediaType = 'pluginCreator___children___internal___mediaType',
  pluginCreator___children___internal___owner = 'pluginCreator___children___internal___owner',
  pluginCreator___children___internal___type = 'pluginCreator___children___internal___type',
  pluginCreator___internal___content = 'pluginCreator___internal___content',
  pluginCreator___internal___contentDigest = 'pluginCreator___internal___contentDigest',
  pluginCreator___internal___description = 'pluginCreator___internal___description',
  pluginCreator___internal___fieldOwners = 'pluginCreator___internal___fieldOwners',
  pluginCreator___internal___ignoreType = 'pluginCreator___internal___ignoreType',
  pluginCreator___internal___mediaType = 'pluginCreator___internal___mediaType',
  pluginCreator___internal___owner = 'pluginCreator___internal___owner',
  pluginCreator___internal___type = 'pluginCreator___internal___type',
  pluginCreator___resolve = 'pluginCreator___resolve',
  pluginCreator___name = 'pluginCreator___name',
  pluginCreator___version = 'pluginCreator___version',
  pluginCreator___pluginOptions___name = 'pluginCreator___pluginOptions___name',
  pluginCreator___pluginOptions___path = 'pluginCreator___pluginOptions___path',
  pluginCreator___pluginOptions___host = 'pluginCreator___pluginOptions___host',
  pluginCreator___pluginOptions___sitemap = 'pluginCreator___pluginOptions___sitemap',
  pluginCreator___pluginOptions___policy = 'pluginCreator___pluginOptions___policy',
  pluginCreator___pluginOptions___policy___userAgent = 'pluginCreator___pluginOptions___policy___userAgent',
  pluginCreator___pluginOptions___policy___allow = 'pluginCreator___pluginOptions___policy___allow',
  pluginCreator___pluginOptions___repositoryName = 'pluginCreator___pluginOptions___repositoryName',
  pluginCreator___pluginOptions___defaultLang = 'pluginCreator___pluginOptions___defaultLang',
  pluginCreator___pluginOptions___langs = 'pluginCreator___pluginOptions___langs',
  pluginCreator___pluginOptions___accessToken = 'pluginCreator___pluginOptions___accessToken',
  pluginCreator___pluginOptions___previewPath = 'pluginCreator___pluginOptions___previewPath',
  pluginCreator___pluginOptions___previews = 'pluginCreator___pluginOptions___previews',
  pluginCreator___pluginOptions___omitPrismicScript = 'pluginCreator___pluginOptions___omitPrismicScript',
  pluginCreator___pluginOptions___id = 'pluginCreator___pluginOptions___id',
  pluginCreator___pluginOptions___includeInDevelopment = 'pluginCreator___pluginOptions___includeInDevelopment',
  pluginCreator___pluginOptions___routeChangeEventName = 'pluginCreator___pluginOptions___routeChangeEventName',
  pluginCreator___pluginOptions___allowList = 'pluginCreator___pluginOptions___allowList',
  pluginCreator___pluginOptions___pathCheck = 'pluginCreator___pluginOptions___pathCheck',
  pluginCreator___nodeAPIs = 'pluginCreator___nodeAPIs',
  pluginCreator___browserAPIs = 'pluginCreator___browserAPIs',
  pluginCreator___ssrAPIs = 'pluginCreator___ssrAPIs',
  pluginCreator___pluginFilepath = 'pluginCreator___pluginFilepath',
  pluginCreator___packageJson___name = 'pluginCreator___packageJson___name',
  pluginCreator___packageJson___description = 'pluginCreator___packageJson___description',
  pluginCreator___packageJson___version = 'pluginCreator___packageJson___version',
  pluginCreator___packageJson___main = 'pluginCreator___packageJson___main',
  pluginCreator___packageJson___author = 'pluginCreator___packageJson___author',
  pluginCreator___packageJson___license = 'pluginCreator___packageJson___license',
  pluginCreator___packageJson___dependencies = 'pluginCreator___packageJson___dependencies',
  pluginCreator___packageJson___dependencies___name = 'pluginCreator___packageJson___dependencies___name',
  pluginCreator___packageJson___dependencies___version = 'pluginCreator___packageJson___dependencies___version',
  pluginCreator___packageJson___devDependencies = 'pluginCreator___packageJson___devDependencies',
  pluginCreator___packageJson___devDependencies___name = 'pluginCreator___packageJson___devDependencies___name',
  pluginCreator___packageJson___devDependencies___version = 'pluginCreator___packageJson___devDependencies___version',
  pluginCreator___packageJson___peerDependencies = 'pluginCreator___packageJson___peerDependencies',
  pluginCreator___packageJson___peerDependencies___name = 'pluginCreator___packageJson___peerDependencies___name',
  pluginCreator___packageJson___peerDependencies___version = 'pluginCreator___packageJson___peerDependencies___version',
  pluginCreator___packageJson___keywords = 'pluginCreator___packageJson___keywords',
  pluginCreatorId = 'pluginCreatorId',
  componentPath = 'componentPath'
}

export type SitePageFilterInput = {
  path?: Maybe<StringQueryOperatorInput>;
  component?: Maybe<StringQueryOperatorInput>;
  internalComponentName?: Maybe<StringQueryOperatorInput>;
  componentChunkName?: Maybe<StringQueryOperatorInput>;
  matchPath?: Maybe<StringQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  isCreatedByStatefulCreatePages?: Maybe<BooleanQueryOperatorInput>;
  context?: Maybe<SitePageContextFilterInput>;
  pluginCreator?: Maybe<SitePluginFilterInput>;
  pluginCreatorId?: Maybe<StringQueryOperatorInput>;
  componentPath?: Maybe<StringQueryOperatorInput>;
};

export type SitePageGroupConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SitePageEdge>;
  nodes: Array<SitePage>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type SitePageSortInput = {
  fields?: Maybe<Array<Maybe<SitePageFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type SitePlugin = Node & {
  id: Scalars['ID'];
  parent?: Maybe<Node>;
  children: Array<Node>;
  internal: Internal;
  resolve?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  pluginOptions?: Maybe<SitePluginPluginOptions>;
  nodeAPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  browserAPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  ssrAPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  pluginFilepath?: Maybe<Scalars['String']>;
  packageJson?: Maybe<SitePluginPackageJson>;
};

export type SitePluginConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SitePluginEdge>;
  nodes: Array<SitePlugin>;
  pageInfo: PageInfo;
  distinct: Array<Scalars['String']>;
  group: Array<SitePluginGroupConnection>;
};


export type SitePluginConnectionDistinctArgs = {
  field: SitePluginFieldsEnum;
};


export type SitePluginConnectionGroupArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  field: SitePluginFieldsEnum;
};

export type SitePluginEdge = {
  next?: Maybe<SitePlugin>;
  node: SitePlugin;
  previous?: Maybe<SitePlugin>;
};

export enum SitePluginFieldsEnum {
  id = 'id',
  parent___id = 'parent___id',
  parent___parent___id = 'parent___parent___id',
  parent___parent___parent___id = 'parent___parent___parent___id',
  parent___parent___parent___children = 'parent___parent___parent___children',
  parent___parent___children = 'parent___parent___children',
  parent___parent___children___id = 'parent___parent___children___id',
  parent___parent___children___children = 'parent___parent___children___children',
  parent___parent___internal___content = 'parent___parent___internal___content',
  parent___parent___internal___contentDigest = 'parent___parent___internal___contentDigest',
  parent___parent___internal___description = 'parent___parent___internal___description',
  parent___parent___internal___fieldOwners = 'parent___parent___internal___fieldOwners',
  parent___parent___internal___ignoreType = 'parent___parent___internal___ignoreType',
  parent___parent___internal___mediaType = 'parent___parent___internal___mediaType',
  parent___parent___internal___owner = 'parent___parent___internal___owner',
  parent___parent___internal___type = 'parent___parent___internal___type',
  parent___children = 'parent___children',
  parent___children___id = 'parent___children___id',
  parent___children___parent___id = 'parent___children___parent___id',
  parent___children___parent___children = 'parent___children___parent___children',
  parent___children___children = 'parent___children___children',
  parent___children___children___id = 'parent___children___children___id',
  parent___children___children___children = 'parent___children___children___children',
  parent___children___internal___content = 'parent___children___internal___content',
  parent___children___internal___contentDigest = 'parent___children___internal___contentDigest',
  parent___children___internal___description = 'parent___children___internal___description',
  parent___children___internal___fieldOwners = 'parent___children___internal___fieldOwners',
  parent___children___internal___ignoreType = 'parent___children___internal___ignoreType',
  parent___children___internal___mediaType = 'parent___children___internal___mediaType',
  parent___children___internal___owner = 'parent___children___internal___owner',
  parent___children___internal___type = 'parent___children___internal___type',
  parent___internal___content = 'parent___internal___content',
  parent___internal___contentDigest = 'parent___internal___contentDigest',
  parent___internal___description = 'parent___internal___description',
  parent___internal___fieldOwners = 'parent___internal___fieldOwners',
  parent___internal___ignoreType = 'parent___internal___ignoreType',
  parent___internal___mediaType = 'parent___internal___mediaType',
  parent___internal___owner = 'parent___internal___owner',
  parent___internal___type = 'parent___internal___type',
  children = 'children',
  children___id = 'children___id',
  children___parent___id = 'children___parent___id',
  children___parent___parent___id = 'children___parent___parent___id',
  children___parent___parent___children = 'children___parent___parent___children',
  children___parent___children = 'children___parent___children',
  children___parent___children___id = 'children___parent___children___id',
  children___parent___children___children = 'children___parent___children___children',
  children___parent___internal___content = 'children___parent___internal___content',
  children___parent___internal___contentDigest = 'children___parent___internal___contentDigest',
  children___parent___internal___description = 'children___parent___internal___description',
  children___parent___internal___fieldOwners = 'children___parent___internal___fieldOwners',
  children___parent___internal___ignoreType = 'children___parent___internal___ignoreType',
  children___parent___internal___mediaType = 'children___parent___internal___mediaType',
  children___parent___internal___owner = 'children___parent___internal___owner',
  children___parent___internal___type = 'children___parent___internal___type',
  children___children = 'children___children',
  children___children___id = 'children___children___id',
  children___children___parent___id = 'children___children___parent___id',
  children___children___parent___children = 'children___children___parent___children',
  children___children___children = 'children___children___children',
  children___children___children___id = 'children___children___children___id',
  children___children___children___children = 'children___children___children___children',
  children___children___internal___content = 'children___children___internal___content',
  children___children___internal___contentDigest = 'children___children___internal___contentDigest',
  children___children___internal___description = 'children___children___internal___description',
  children___children___internal___fieldOwners = 'children___children___internal___fieldOwners',
  children___children___internal___ignoreType = 'children___children___internal___ignoreType',
  children___children___internal___mediaType = 'children___children___internal___mediaType',
  children___children___internal___owner = 'children___children___internal___owner',
  children___children___internal___type = 'children___children___internal___type',
  children___internal___content = 'children___internal___content',
  children___internal___contentDigest = 'children___internal___contentDigest',
  children___internal___description = 'children___internal___description',
  children___internal___fieldOwners = 'children___internal___fieldOwners',
  children___internal___ignoreType = 'children___internal___ignoreType',
  children___internal___mediaType = 'children___internal___mediaType',
  children___internal___owner = 'children___internal___owner',
  children___internal___type = 'children___internal___type',
  internal___content = 'internal___content',
  internal___contentDigest = 'internal___contentDigest',
  internal___description = 'internal___description',
  internal___fieldOwners = 'internal___fieldOwners',
  internal___ignoreType = 'internal___ignoreType',
  internal___mediaType = 'internal___mediaType',
  internal___owner = 'internal___owner',
  internal___type = 'internal___type',
  resolve = 'resolve',
  name = 'name',
  version = 'version',
  pluginOptions___name = 'pluginOptions___name',
  pluginOptions___path = 'pluginOptions___path',
  pluginOptions___host = 'pluginOptions___host',
  pluginOptions___sitemap = 'pluginOptions___sitemap',
  pluginOptions___policy = 'pluginOptions___policy',
  pluginOptions___policy___userAgent = 'pluginOptions___policy___userAgent',
  pluginOptions___policy___allow = 'pluginOptions___policy___allow',
  pluginOptions___repositoryName = 'pluginOptions___repositoryName',
  pluginOptions___defaultLang = 'pluginOptions___defaultLang',
  pluginOptions___langs = 'pluginOptions___langs',
  pluginOptions___accessToken = 'pluginOptions___accessToken',
  pluginOptions___previewPath = 'pluginOptions___previewPath',
  pluginOptions___previews = 'pluginOptions___previews',
  pluginOptions___omitPrismicScript = 'pluginOptions___omitPrismicScript',
  pluginOptions___id = 'pluginOptions___id',
  pluginOptions___includeInDevelopment = 'pluginOptions___includeInDevelopment',
  pluginOptions___routeChangeEventName = 'pluginOptions___routeChangeEventName',
  pluginOptions___allowList = 'pluginOptions___allowList',
  pluginOptions___pathCheck = 'pluginOptions___pathCheck',
  nodeAPIs = 'nodeAPIs',
  browserAPIs = 'browserAPIs',
  ssrAPIs = 'ssrAPIs',
  pluginFilepath = 'pluginFilepath',
  packageJson___name = 'packageJson___name',
  packageJson___description = 'packageJson___description',
  packageJson___version = 'packageJson___version',
  packageJson___main = 'packageJson___main',
  packageJson___author = 'packageJson___author',
  packageJson___license = 'packageJson___license',
  packageJson___dependencies = 'packageJson___dependencies',
  packageJson___dependencies___name = 'packageJson___dependencies___name',
  packageJson___dependencies___version = 'packageJson___dependencies___version',
  packageJson___devDependencies = 'packageJson___devDependencies',
  packageJson___devDependencies___name = 'packageJson___devDependencies___name',
  packageJson___devDependencies___version = 'packageJson___devDependencies___version',
  packageJson___peerDependencies = 'packageJson___peerDependencies',
  packageJson___peerDependencies___name = 'packageJson___peerDependencies___name',
  packageJson___peerDependencies___version = 'packageJson___peerDependencies___version',
  packageJson___keywords = 'packageJson___keywords'
}

export type SitePluginFilterInput = {
  id?: Maybe<StringQueryOperatorInput>;
  parent?: Maybe<NodeFilterInput>;
  children?: Maybe<NodeFilterListInput>;
  internal?: Maybe<InternalFilterInput>;
  resolve?: Maybe<StringQueryOperatorInput>;
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
  pluginOptions?: Maybe<SitePluginPluginOptionsFilterInput>;
  nodeAPIs?: Maybe<StringQueryOperatorInput>;
  browserAPIs?: Maybe<StringQueryOperatorInput>;
  ssrAPIs?: Maybe<StringQueryOperatorInput>;
  pluginFilepath?: Maybe<StringQueryOperatorInput>;
  packageJson?: Maybe<SitePluginPackageJsonFilterInput>;
};

export type SitePluginGroupConnection = {
  totalCount: Scalars['Int'];
  edges: Array<SitePluginEdge>;
  nodes: Array<SitePlugin>;
  pageInfo: PageInfo;
  field: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJson = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  main?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  dependencies?: Maybe<Array<Maybe<SitePluginPackageJsonDependencies>>>;
  devDependencies?: Maybe<Array<Maybe<SitePluginPackageJsonDevDependencies>>>;
  peerDependencies?: Maybe<Array<Maybe<SitePluginPackageJsonPeerDependencies>>>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SitePluginPackageJsonDependencies = {
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJsonDependenciesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonDependenciesFilterListInput = {
  elemMatch?: Maybe<SitePluginPackageJsonDependenciesFilterInput>;
};

export type SitePluginPackageJsonDevDependencies = {
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJsonDevDependenciesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonDevDependenciesFilterListInput = {
  elemMatch?: Maybe<SitePluginPackageJsonDevDependenciesFilterInput>;
};

export type SitePluginPackageJsonFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
  main?: Maybe<StringQueryOperatorInput>;
  author?: Maybe<StringQueryOperatorInput>;
  license?: Maybe<StringQueryOperatorInput>;
  dependencies?: Maybe<SitePluginPackageJsonDependenciesFilterListInput>;
  devDependencies?: Maybe<SitePluginPackageJsonDevDependenciesFilterListInput>;
  peerDependencies?: Maybe<SitePluginPackageJsonPeerDependenciesFilterListInput>;
  keywords?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonPeerDependencies = {
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SitePluginPackageJsonPeerDependenciesFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  version?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPackageJsonPeerDependenciesFilterListInput = {
  elemMatch?: Maybe<SitePluginPackageJsonPeerDependenciesFilterInput>;
};

export type SitePluginPluginOptions = {
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  sitemap?: Maybe<Array<Maybe<Scalars['String']>>>;
  policy?: Maybe<Array<Maybe<SitePluginPluginOptionsPolicy>>>;
  repositoryName?: Maybe<Scalars['String']>;
  defaultLang?: Maybe<Scalars['String']>;
  langs?: Maybe<Array<Maybe<Scalars['String']>>>;
  accessToken?: Maybe<Scalars['String']>;
  previewPath?: Maybe<Scalars['String']>;
  previews?: Maybe<Scalars['Boolean']>;
  omitPrismicScript?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  includeInDevelopment?: Maybe<Scalars['Boolean']>;
  routeChangeEventName?: Maybe<Scalars['String']>;
  allowList?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathCheck?: Maybe<Scalars['Boolean']>;
};

export type SitePluginPluginOptionsFilterInput = {
  name?: Maybe<StringQueryOperatorInput>;
  path?: Maybe<StringQueryOperatorInput>;
  host?: Maybe<StringQueryOperatorInput>;
  sitemap?: Maybe<StringQueryOperatorInput>;
  policy?: Maybe<SitePluginPluginOptionsPolicyFilterListInput>;
  repositoryName?: Maybe<StringQueryOperatorInput>;
  defaultLang?: Maybe<StringQueryOperatorInput>;
  langs?: Maybe<StringQueryOperatorInput>;
  accessToken?: Maybe<StringQueryOperatorInput>;
  previewPath?: Maybe<StringQueryOperatorInput>;
  previews?: Maybe<BooleanQueryOperatorInput>;
  omitPrismicScript?: Maybe<BooleanQueryOperatorInput>;
  id?: Maybe<StringQueryOperatorInput>;
  includeInDevelopment?: Maybe<BooleanQueryOperatorInput>;
  routeChangeEventName?: Maybe<StringQueryOperatorInput>;
  allowList?: Maybe<StringQueryOperatorInput>;
  pathCheck?: Maybe<BooleanQueryOperatorInput>;
};

export type SitePluginPluginOptionsPolicy = {
  userAgent?: Maybe<Scalars['String']>;
  allow?: Maybe<Scalars['String']>;
};

export type SitePluginPluginOptionsPolicyFilterInput = {
  userAgent?: Maybe<StringQueryOperatorInput>;
  allow?: Maybe<StringQueryOperatorInput>;
};

export type SitePluginPluginOptionsPolicyFilterListInput = {
  elemMatch?: Maybe<SitePluginPluginOptionsPolicyFilterInput>;
};

export type SitePluginSortInput = {
  fields?: Maybe<Array<Maybe<SitePluginFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export type SiteSiteMetadata = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  siteUrl?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type SiteSiteMetadataFilterInput = {
  title?: Maybe<StringQueryOperatorInput>;
  description?: Maybe<StringQueryOperatorInput>;
  author?: Maybe<StringQueryOperatorInput>;
  lang?: Maybe<StringQueryOperatorInput>;
  siteUrl?: Maybe<StringQueryOperatorInput>;
  image?: Maybe<StringQueryOperatorInput>;
};

export type SiteSortInput = {
  fields?: Maybe<Array<Maybe<SiteFieldsEnum>>>;
  order?: Maybe<Array<Maybe<SortOrderEnum>>>;
};

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type StringQueryOperatorInput = {
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['String']>;
  glob?: Maybe<Scalars['String']>;
};

export type BenefitQuadrantHomeFragment = (
  Pick<Prismic_HomeBody1Benefit_Quadrant, 'type' | 'label'>
  & { primary?: Maybe<Pick<Prismic_HomeBody1Benefit_QuadrantPrimary, 'headline' | 'subheadline' | 'footer'>>, fields?: Maybe<Array<Pick<Prismic_HomeBody1Benefit_QuadrantFields, 'title' | 'description' | 'image'>>> }
);

export type BenefitQuadrantStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyBenefit_Quadrant, 'type' | 'label'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyBenefit_QuadrantPrimary, 'headline' | 'subheadline' | 'footer'>>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyBenefit_QuadrantFields, 'title' | 'description' | 'image'>>> }
);

export type CenteredFullWidthCtaStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyCentered_Full_Width_Cta, 'type'>
  & { primary?: Maybe<(
    Pick<Prismic_Standard_Landing_PageBodyCentered_Full_Width_CtaPrimary, 'headline' | 'cta_action' | 'cta_text' | 'drift_bot_interaction_id'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, '_linkType' | 'url'>> }
  )> }
);

export type CenteredFullWidthCtaHomeFragment = (
  Pick<Prismic_HomeBody1Centered_Full_Width_Cta, 'type'>
  & { primary?: Maybe<(
    Pick<Prismic_HomeBody1Centered_Full_Width_CtaPrimary, 'headline' | 'cta_action' | 'cta_text' | 'drift_bot_interaction_id'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, '_linkType' | 'url'>> }
  )> }
);

export type CountUpHomeFragment = (
  Pick<Prismic_HomeBody1Count_Up, 'type'>
  & { primaryFields?: Maybe<Pick<Prismic_HomeBody1Count_UpPrimary, 'subtitle' | 'title'>>, categories?: Maybe<Array<Pick<Prismic_HomeBody1Count_UpFields, 'category_image' | 'category_title' | 'number' | 'suffix'>>> }
);

export type FeaturedCustomersHomeFragment = (
  Pick<Prismic_HomeBody1Featured_Customers, 'type' | 'label'>
  & { primary?: Maybe<Pick<Prismic_HomeBody1Featured_CustomersPrimary, 'subtitle'>>, fields?: Maybe<Array<(
    Pick<Prismic_HomeBody1Featured_CustomersFields, 'company_logo'>
    & { company_link?: Maybe<Pick<Prismic__ExternalLink, 'url'>> }
  )>> }
);

export type FeaturedCustomersStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyFeatured_Customers, 'type' | 'label'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyFeatured_CustomersPrimary, 'subtitle'>>, fields?: Maybe<Array<(
    Pick<Prismic_Standard_Landing_PageBodyFeatured_CustomersFields, 'company_logo'>
    & { company_link?: Maybe<Pick<Prismic__ExternalLink, 'url'>> }
  )>> }
);

export type FeaturedVideoStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyYoutube_Video, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyYoutube_VideoPrimary, 'title' | 'description' | 'url'>> }
);

export type FooterQueryVariables = Exact<{ [key: string]: never; }>;


export type FooterQuery = { prismic: { allFooters: { edges?: Maybe<Array<Maybe<{ node: (
          Pick<Prismic_Footer, 'column_title_1' | 'column_title_2' | 'column_title_3'>
          & { column_text_1?: Maybe<Array<Pick<Prismic_FooterColumn_Text_1, 'column_item'>>>, column_text_2?: Maybe<Array<Pick<Prismic_FooterColumn_Text_2, 'column_item'>>>, column_text_3?: Maybe<Array<Pick<Prismic_FooterColumn_Text_3, 'column_item'>>>, legal_links?: Maybe<Array<Pick<Prismic_FooterLegal_Links, 'legal_link'>>> }
        ) }>>> } } };

export type FullWidthCtaStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyFull_Width_Cta, 'type'>
  & { primary?: Maybe<(
    Pick<Prismic_Standard_Landing_PageBodyFull_Width_CtaPrimary, 'cta_text' | 'cta_action' | 'drift_bot_interaction_id' | 'heading' | 'image' | 'fine_print'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, 'url'>> }
  )> }
);

export type HeaderCtAsNodeFragment = { _meta: Pick<Prismic_Meta, 'uid'>, ctas?: Maybe<Array<(
    Pick<Prismic_Header_CtasCtas, 'cta_text' | 'cta_action' | 'drift_bot_interaction_id'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, 'target' | 'url'>> }
  )>> };

export type HeaderCtAsQueryVariables = Exact<{ [key: string]: never; }>;


export type HeaderCtAsQuery = { prismic: { allHeader_ctass: { edges?: Maybe<Array<Maybe<{ node: HeaderCtAsNodeFragment }>>> } } };

export type HeaderLinksNodeFragment = { _meta: Pick<Prismic_Meta, 'uid'>, body?: Maybe<Array<(
    Pick<Prismic_Header_LinksBodyDrawer_Button, 'type'>
    & { primary?: Maybe<Pick<Prismic_Header_LinksBodyDrawer_ButtonPrimary, 'drawer_button_text'>> }
  ) | (
    Pick<Prismic_Header_LinksBodyDrawer_Column, 'type'>
    & { fields?: Maybe<Array<Pick<Prismic_Header_LinksBodyDrawer_ColumnFields, 'link'>>>, primary?: Maybe<Pick<Prismic_Header_LinksBodyDrawer_ColumnPrimary, 'column_title'>> }
  )>>, page_links?: Maybe<Array<(
    Pick<Prismic_Header_LinksPage_Links, 'link_text'>
    & { link?: Maybe<Pick<Prismic__ExternalLink, 'target' | 'url'>> }
  )>> };

export type HeaderLinksQueryVariables = Exact<{ [key: string]: never; }>;


export type HeaderLinksQuery = { prismic: { allHeader_linkss: { edges?: Maybe<Array<Maybe<{ node: HeaderLinksNodeFragment }>>> } } };

export type HeroCenteredStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyCentered_Hero, 'type'>
  & { primary?: Maybe<(
    Pick<Prismic_Standard_Landing_PageBodyCentered_HeroPrimary, 'title' | 'subtitle' | 'cta_text' | 'cta_microcopy' | 'image'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, '_linkType' | 'url'>> }
  )> }
);

export type HeroHomeFragment = (
  Pick<Prismic_HomeBody1Home_Hero, 'type'>
  & { primary?: Maybe<(
    Pick<Prismic_HomeBody1Home_HeroPrimary, 'title' | 'subtitle' | 'image' | 'cta_text'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, 'url'>> }
  )> }
);

export type HeroLandingStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyLanding_Page_Hero, 'type'>
  & { primary?: Maybe<(
    Pick<Prismic_Standard_Landing_PageBodyLanding_Page_HeroPrimary, 'cta_text' | 'image' | 'subtitle' | 'title' | 'cta_action' | 'drift_bot_interaction_id' | 'cta_microcopy'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, '_linkType' | 'url'>> }
  )>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyLanding_Page_HeroFields, 'quick_fact_text' | 'quick_fact_icon'>>> }
);

export type StandardLandingPageImageCarouselFragment = (
  Pick<Prismic_Standard_Landing_PageBodyImage_Carousel, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyImage_CarouselPrimary, 'primary_caption'>>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyImage_CarouselFields, 'image1' | 'image_caption'>>> }
);

export type ImageGridStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyImage_Grid, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyImage_GridPrimary, 'title'>>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyImage_GridFields, 'title' | 'subtitle' | 'image' | 'description'>>> }
);

export type StandardLandingPageListAndImageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyImageAndList, 'type'>
  & { fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyImageAndListFields, 'name' | 'description' | 'image1'>>>, primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyImageAndListPrimary, 'title1'>> }
);

export type MediaMarqueeHomeFragment = (
  Pick<Prismic_HomeBody1Media_Marquee, 'type'>
  & { fields?: Maybe<Array<Pick<Prismic_HomeBody1Media_MarqueeFields, 'feature_title' | 'feature_description' | 'feature_image'>>>, primary?: Maybe<(
    Pick<Prismic_HomeBody1Media_MarqueePrimary, 'headline' | 'video_url' | 'footer'>
    & { mediaMarqueeDescription: Prismic_HomeBody1Media_MarqueePrimary['description'] }
  )> }
);

export type MediaMarqueeStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyMedia_Marquee, 'type'>
  & { fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyMedia_MarqueeFields, 'feature_title' | 'feature_description' | 'feature_image'>>>, primary?: Maybe<(
    Pick<Prismic_Standard_Landing_PageBodyMedia_MarqueePrimary, 'headline' | 'video_url' | 'footer'>
    & { mediaMarqueeDescription: Prismic_Standard_Landing_PageBodyMedia_MarqueePrimary['description'] }
  )> }
);

export type NestedContentStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyNested_Content, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyNested_ContentPrimary, 'title'>>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyNested_ContentFields, 'content' | 'header'>>> }
);

export type NewsletterSignupHomeFragment = (
  Pick<Prismic_HomeBody1Newsletter_Signup, 'type'>
  & { primary?: Maybe<Pick<Prismic_HomeBody1Newsletter_SignupPrimary, 'description' | 'cta_button_text'>> }
);

export type NewsletterSignupStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyNewsletter_Signup, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyNewsletter_SignupPrimary, 'description' | 'cta_button_text'>> }
);

export type StandardLandingPageRichTextContentFragment = (
  Pick<Prismic_Standard_Landing_PageBodyRich_Text, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyRich_TextPrimary, 'title1' | 'rich_text'>> }
);

export type SimpleInlineCtaHomeFragment = (
  Pick<Prismic_HomeBody1Simple_Inline_Cta, 'type'>
  & { primary?: Maybe<(
    Pick<Prismic_HomeBody1Simple_Inline_CtaPrimary, 'subheadline' | 'headline' | 'cta_text' | 'cta_action' | 'drift_bot_interaction_id'>
    & { cta_link?: Maybe<Pick<Prismic__ExternalLink, 'target' | '_linkType' | 'url'>> }
  )> }
);

export type TableStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyTable, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyTablePrimary, 'heading' | 'table_title' | 'table_caption_rich_text' | 'column_1_header' | 'column_2_header' | 'column_3_header' | 'column_4_header' | 'column_5_header'>>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyTableFields, 'column_1_entry' | 'column_2_entry' | 'column_3_entry' | 'column_4_entry' | 'column_5_entry'>>> }
);

export type TestimonialsHomeFragment = (
  Pick<Prismic_HomeBody1Testimonials, 'type'>
  & { primary?: Maybe<Pick<Prismic_HomeBody1TestimonialsPrimary, 'title'>>, fields?: Maybe<Array<Pick<Prismic_HomeBody1TestimonialsFields, 'company_image' | 'person_image' | 'person_name' | 'person_title' | 'quote'>>> }
);

export type TestimonialStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyTestimonial, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyTestimonialPrimary, 'company_image' | 'person_image' | 'person_name' | 'person_title' | 'quote'>> }
);

export type TestimonialsStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyTestimonials, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyTestimonialsPrimary, 'title'>>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyTestimonialsFields, 'company_image' | 'person_image' | 'person_name' | 'person_title' | 'quote'>>> }
);

export type TextAndImageHomeFragment = (
  Pick<Prismic_HomeBody1Text_And_Image, 'type' | 'label'>
  & { primary?: Maybe<Pick<Prismic_HomeBody1Text_And_ImagePrimary, 'image' | 'text' | 'title' | 'display_new_badge'>>, fields?: Maybe<Array<Pick<Prismic_HomeBody1Text_And_ImageFields, 'bullet_point'>>> }
);

export type TextAndImageStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyText_And_Image, 'type' | 'label'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyText_And_ImagePrimary, 'image' | 'text' | 'title' | 'display_new_badge'>>, fields?: Maybe<Array<Pick<Prismic_Standard_Landing_PageBodyText_And_ImageFields, 'bullet_point'>>> }
);

export type ThreeSimpleCardsStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyThree_Simple_Cards, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyThree_Simple_CardsPrimary, 'card_1_description' | 'card_1_image' | 'card_1_title' | 'card_2_description' | 'card_2_image' | 'card_2_title' | 'card_3_description' | 'card_3_image' | 'card_3_title' | 'title'>> }
);

export type ThreeSimpleCirclesStandardLandingPageFragment = (
  Pick<Prismic_Standard_Landing_PageBodyThree_Simple_Circles, 'type'>
  & { primary?: Maybe<Pick<Prismic_Standard_Landing_PageBodyThree_Simple_CirclesPrimary, 'circle_1_image' | 'circle_1_description' | 'circle_1_title' | 'circle_2_description' | 'circle_2_image' | 'circle_2_title' | 'circle_3_description' | 'circle_3_image' | 'circle_3_title' | 'title'>> }
);

export type HomePagesQueryVariables = Exact<{ [key: string]: never; }>;


export type HomePagesQuery = { prismic: { allHomes: { edges?: Maybe<Array<Maybe<{ node: { _meta: Pick<Prismic_Meta, 'lang' | 'uid' | 'type'> } }>>> } } };

export type AllRedirectsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllRedirectsQuery = { prismic: { allRedirectss: { edges?: Maybe<Array<Maybe<{ node: { redirect?: Maybe<Array<Pick<Prismic_RedirectsRedirect, 'new_url' | 'old_url'>>> } }>>> } } };

export type StandardLandingPagesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type StandardLandingPagesQuery = { prismic: { allStandard_landing_pages: { pageInfo: Pick<Prismic_PageInfo, 'hasNextPage' | 'endCursor'>, edges?: Maybe<Array<Maybe<{ node: { _meta: Pick<Prismic_Meta, 'lang' | 'uid' | 'type'> } }>>> } } };

export type HomeQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeQuery = { prismic: { allHomes: { edges?: Maybe<Array<Maybe<{ node: { body1?: Maybe<Array<HeroHomeFragment | CountUpHomeFragment | FeaturedCustomersHomeFragment | BenefitQuadrantHomeFragment | MediaMarqueeHomeFragment | TextAndImageHomeFragment | TestimonialsHomeFragment | SimpleInlineCtaHomeFragment | NewsletterSignupHomeFragment | CenteredFullWidthCtaHomeFragment>>, body?: Maybe<Array<{ primary?: Maybe<Pick<Prismic_HomeBodySeo_Primary, 'meta_description' | 'meta_title'>> }>>, _meta: Pick<Prismic_Meta, 'lang'> } }>>> } } };

export type StandardLandingPageQueryVariables = Exact<{
  uid: Scalars['String'];
  lang: Scalars['String'];
}>;


export type StandardLandingPageQuery = { prismic: { standard_landing_page?: Maybe<{ header_links?: Maybe<{ _meta: Pick<Prismic_Meta, 'uid'> }>, header_ctas?: Maybe<{ _meta: Pick<Prismic_Meta, 'uid'> }>, body?: Maybe<Array<HeroLandingStandardLandingPageFragment | TestimonialStandardLandingPageFragment | ThreeSimpleCardsStandardLandingPageFragment | FullWidthCtaStandardLandingPageFragment | TextAndImageStandardLandingPageFragment | ThreeSimpleCirclesStandardLandingPageFragment | FeaturedVideoStandardLandingPageFragment | NestedContentStandardLandingPageFragment | TableStandardLandingPageFragment | HeroCenteredStandardLandingPageFragment | ImageGridStandardLandingPageFragment | StandardLandingPageRichTextContentFragment | BenefitQuadrantStandardLandingPageFragment | MediaMarqueeStandardLandingPageFragment | TestimonialsStandardLandingPageFragment | StandardLandingPageListAndImageFragment | StandardLandingPageImageCarouselFragment | FeaturedCustomersStandardLandingPageFragment | NewsletterSignupStandardLandingPageFragment | CenteredFullWidthCtaStandardLandingPageFragment>>, body1?: Maybe<Array<{ primary?: Maybe<Pick<Prismic_Standard_Landing_PageBody1Seo_Primary, 'meta_description' | 'meta_title'>> }>>, _meta: Pick<Prismic_Meta, 'lang'> }> } };
