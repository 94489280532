import styled from "@emotion/styled";
import theme from "../../styles/theme";
import { Input, FormMessage, Alert } from "@xometry/xometry_loft";
import CTA from "../cta/cta";
import { INewsletterFormProps } from "./newsletter-form";

export const NewsletterFormBase = styled.div<INewsletterFormProps>`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;

  ${theme.largerThan.medium} {
    justify-content: ${(props) =>
      props.alignment == "start"
        ? "flex-start"
        : props.alignment == "center"
        ? "center"
        : "flex-end"};
  }
`;

export const StyledAlert = styled(Alert)<{}>`
  width: fit-content;
`;

export const InputGroup = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  flex: 1 0 auto;

  ${theme.largerThan.medium} {
    width: initial;
  }
`;

export const NewsletterFormElement = styled.form`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${theme.largerThan.medium} {
    flex-direction: row;
    justify-self: flex-end;
    width: initial;
  }
`;

export const StyledInput = styled(Input)<{}>`
  margin: 0 0 8px 0;
  flex: 1 0 auto;

  ${theme.largerThan.medium} {
    margin: 0 8px 0 0;
  }
`;

export const StyledFormMessage = styled(FormMessage)<{}>`
  position: static;
  color: ${theme.colors.reds[25]};
  top: -24px;
  margin-bottom: 2px;

  ${theme.largerThan.medium} {
    position: absolute;
  }
`;

export const StyledCTA = styled(CTA)`
  white-space: nowrap;
  width: 100%;
  flex: 0 1 auto;

  ${theme.largerThan.medium} {
    width: initial;
  }
` as typeof CTA;
