export function newsletterSignupSubmit(email: string) {
  const url =
    "https://api.hsforms.com/submissions/v3/integration/submit/340051/800bec2e-8ba3-4155-ae15-775f40a52950";

  var payload = JSON.stringify({
    submittedAt: Date.now(),
    fields: [
      {
        name: "email",
        value: email,
      },
    ],
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
  });

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: payload,
  }).then((res) => res.json());
}

export function getDotNeutralData() {
  const apiUrl = "https://app.dotneutral.com/v1/stats?api_key=";
  const pubKey = "pub.16ehQh88vjE00VvIgy98O0u07yI66Va8";

  return fetch(apiUrl + pubKey, {
    cache: "force-cache",
  }).then((res) => res.json());
}
