import React, { useState } from "react";
import {
  StyledAlert,
  StyledInput,
  InputGroup,
  StyledFormMessage,
  StyledCTA,
  NewsletterFormElement,
  NewsletterFormBase,
} from "./newsletter-form.styled";
import {} from "../../types/graphql";
import { useFormik, FormikValues } from "formik";
import { string as yupString, object as yupObject } from "yup";
import { newsletterSignupSubmit } from "../../utils/apiRequests";
import { InputScale, ButtonScale, ButtonKind } from "@xometry/xometry_loft";

export interface INewsletterFormProps {
  ctaScale: ButtonScale;
  ctaKind: ButtonKind;
  inputScale: InputScale;
  eventPageSection: string;
  alignment: "start" | "end" | "center";
}

export const NewsletterForm = (props: INewsletterFormProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit(values: FormikValues) {
    let res = await newsletterSignupSubmit(values.email)
      .then((res) => {
        setSubmitted(true);
        if (res.status !== "error") setSuccess(true);
      })
      .catch(() => {
        setSubmitted(true);
      });
    return res;
  }

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yupObject({
      email: yupString().email("* Invalid email").required("* Required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <NewsletterFormBase {...props}>
      {submitted && success && (
        <StyledAlert isOpen={true} kind="primary">
          Thanks for signing up!
        </StyledAlert>
      )}
      {submitted && !success && (
        <StyledAlert isOpen={true} kind="danger">
          Unable to submit. Please try again.
        </StyledAlert>
      )}
      {!submitted && (
        <NewsletterFormElement onSubmit={formik.handleSubmit}>
          <InputGroup>
            {formik.errors.email && formik.touched.email && (
              <StyledFormMessage invalid={true}>
                {formik.errors.email}
              </StyledFormMessage>
            )}
            <StyledInput
              scale={props.inputScale}
              name="email"
              placeholder="Your Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              invalid={
                Object.keys(formik.errors).length !== 0 && formik.touched.email
              }
            />
          </InputGroup>
          <StyledCTA
            scale={props.ctaScale}
            kind={props.ctaKind}
            type="submit"
            pageSection={props.eventPageSection}
            ctaText="Subscribe"
            additionalEventProps={{
              email: formik.values.email,
            }}
          />
        </NewsletterFormElement>
      )}
    </NewsletterFormBase>
  );
};

export default NewsletterForm;
